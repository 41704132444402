@import "../../../styles/variables.module";

$extra-padding: 0rem;
$stations-width: 16rem;
$stations-width-mobile: 9.6rem;
$station-horizontal-padding: 1rem;

body:global(.arrow-navigation) {
  .radio_station {
    &:focus {
      @include media(">phoneXL") {
        transform: scale(1.2);
        filter: drop-shadow(0px 0px 24px #000000);
      }
    }
  }
}

body:global(.low-performance) {
  .header,
  .radio_station {
    transition: none !important;
  }
  .placeholder_item {
    &:before {
      display: none;
    }
  }
}

.wrapper {

}

.header {
  display: flex;
  justify-content: space-between;
  transform: translateY(3.2rem);
  padding: 0 0 0 1.6rem;
  position: relative;
  z-index: $z-index-base + 1;

  @include media(">tablet") {
    padding-right: 1.6rem;
  }

  @include media("<=tablet") {
    padding: 0;
    align-items: center;
  }

  h2 {
    font-size: 2.4rem;
    color: $color-light;
    font-weight: 400;

    @include media("<=desktop720p") {
      font-size: 2rem;
    }
    @include media("<tablet") {
      font-size: 1.6rem;
      // line-height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .buttons {
    flex-shrink: 0;
    direction: ltr;

    @include media("<tablet") {
      display: none;
    }
  }

  .arrow {
    border-radius: 50%;
    &:first-child {
      margin-right: 1.6rem;
    }
    svg {
      width: 4rem;
      height: 4rem;
    }
    &.arrow_back {
      svg {
        transform: rotate(180deg);
      }
    }

    &:hover {
      svg {
        border-radius: 50%;
        background-color: rgba($color-light, 0.15);
      }
    }
  }
}

.radio_list {
  padding: calc(2.2rem + #{$extra-padding}) 1.6rem;
  margin: -1 * $extra-padding -1.6rem;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  html[dir="rtl"] & {
    @include media(">desktop720p") {
      padding: calc(2.2rem + #{$extra-padding}) 5rem;
      margin: -1 * $extra-padding -5rem;
      margin-left: -3.4rem;
    }
  }
  @include media("<=tablet") {
    padding: calc(3.2rem + #{$extra-padding}) 0rem $extra-padding 0.8rem;
  }
  @include media("<=phoneXL") {
    padding: calc(3.2rem + #{$extra-padding}) 0rem $extra-padding;
  }

  @include media(">desktop720p") {
    padding: calc(3.2rem + #{$extra-padding}) 5rem;
    margin: -1 * $extra-padding -5rem;
    margin-right: -3.4rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.big {
    @include media("<=desktop720p") {
      margin: 0 -1.6rem 0 -0.8rem;
      width: calc(100% + 2.4rem);
    }
    @include media("<=tablet") {
      padding-top: 0;
      margin: 0;
      padding-left: 0;
      width: 100%;
    }
  }
}

.radio_station {
  transition: 0.5s all;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
  z-index: $z-index-base;
  display: inline-block;
  overflow: visible;
  padding: 1.6rem 1rem;

  @include media("<=tablet") {
    padding: 1.6rem 0.8rem;
  }
  @include media("<=phoneXL") {
    padding: 1.6rem 0.6rem;
  }

  &:hover {
    @include media(">phoneXL") {
      transform: scale(1.1);
      .cover_wrapper {
        // box-shadow: 0px 0px 16px 0px #000;
      }
    }
  }

  &:focus {
    outline: none;
  }

  .cover_wrapper {
    transition: 0.5s all;
    position: relative;
    width: $stations-width;
    height: $stations-width;
    box-shadow: 0px 8.04998px 11.50097px 0px rgba(0, 0, 0, 0.4);

    @include media("<=desktop720p") {
      width: 14rem;
      height: 14rem;
    }

    @include media("<=tablet") {
      width: 12rem;
      height: 12rem;
    }
  }

  .cover {
    border-radius: 8px;
    box-shadow: inset 0 0 10px 7px $color-light-transparent-15;

    &.no_image {
      background: $color-light-transparent-15;
      width: $stations-width;
      height: $stations-width;
      position: relative;
      padding: 1rem;

      @include media("<=tablet") {
        width: 12rem;
        height: 12rem;
      }
      img {
        object-fit: contain;
      }

      .no_image_img {
        padding: 1rem;
        height: 100%;
        position: relative;
      }
    }
  }

  .station_title {
    color: $color-light;
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    max-width: $stations-width;

    @include media("<tablet") {
      max-width: $stations-width-mobile;
    }
  }

  &.show_station_names.show_station_names {
    margin-bottom: 3.2rem;
  }

  &.big {

    .cover_wrapper {
      width: 18rem;
      height: 18rem;

      @include media("<=desktop720p") {
        width: 14rem;
        height: 14rem;
      }
      @include media("<=tablet") {
        width: 11.5rem;
        height: 11.5rem;
      }
    }
    
  }
}

.row {
  display: flex;

  &::after {
    content: "";
    flex: 0 0 calc(100vw - 17.2rem - 4.2rem);

    @include media("<=phoneXL") {
      flex: 0 0 calc(100vw - 17.2rem - 0.8rem);
    }
  }

  @include media("<=phoneXL") {
    padding-left: 1rem;

    .big & {
      padding-left: 0;
    }
  }

  &.vertical {
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      content: "";
      flex: 0;
    }
    .station_title {
      display: none;
    }
    .radio_station {
      margin-bottom: 0;
    }
  }
  &.wrapped {
    overflow-x: hidden;
    flex-wrap: wrap;
    padding-bottom: 3rem;

    .placeholder_item,
    .radio_station {
      margin: 1rem;
    }
    .radio_station.big {
      margin: 1rem 0 1rem 0.7rem;
    }

    @include media("<=desktop720p") {

      .radio_station.big {
        padding: 0;
        margin-right: 2rem;
      }
    }
    @include media("<=tablet") {
      .big & {
        gap: 1.6rem;
        
        &::after {
          display: none;
        }
        &.over_two {
          justify-content: center;
        }
      }
      .radio_station.big {
        margin: 0;
      }
    }

    .placeholder_item {
      @include media("<=phoneXL") {
        width: 12rem;
        height: 12rem;
      }
    }
    .radio_station {
      @include media("<=phoneXL") {
        margin: 0rem;
      }
    }
  }

  &.placeholder_row {
    margin-bottom: 0.4rem;
  }
}

.placeholder_item {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  // border-radius: 10px;
  width: $stations-width;
  height: $stations-width;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  .cover {
    opacity: 0;
  }

  margin: 1.6rem 2rem;
  // padding: 0;

  // @include media("<=tablet") {
  //   margin: 1.8rem 0.8rem 1.9rem;
  // }
  // @include media("<=phoneXL") {
  //   margin: 1.6rem 0.6rem;
  // }

  // @include media("<=desktop720p") {
  //   width: 14rem;
  //   height: 14rem;
  // }

  // @include media("<=tablet") {
  //   width: 12rem;
  //   height: 12rem;
  // }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: $stations-width;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(#e8e8e8, 0.2) 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;

    @include media("<tablet") {
      width: $stations-width-mobile;
    }
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

:export {
  radioStationOuterWidth: $stations-width + $station-horizontal-padding + 1.6rem;
  radioStationOuterWidthMobile: $stations-width-mobile +
    $station-horizontal-padding + 1.6rem;
}
