@import "../../../styles/variables.module";

$category-width: 16rem;
$category-padding: 1rem;

.category_list {
  --category-width: 16rem;

  @include media("<=desktop720p") {
    --category-width: 12rem;
  }
  @include media("<=phoneXL") {
    --category-width: 10.7rem;
  }
}

.header {
  font-size: 2.4rem;
  color: $color-light;
  font-weight: 400;
  padding-left: $base-padding;
  display: flex;
  justify-content: space-between;

  @include media("<=desktop720p") {
    font-size: 2rem;
  }
  @include media("<=tablet") {
    font-size: 1.6rem;
    padding-left: 0;
  }

  .buttons {
    flex-shrink: 0;
    direction: ltr;
  }

  span {
    @include media("<tablet") {
      font-size: 1.6rem;
      line-height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .arrow {
    position: relative;
    z-index: 1;
    &:first-child {
      margin-right: 1.6rem;
    }
    svg {
      width: 4rem;
      height: 4rem;
    }
    &.arrow_back {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

body:global(.arrow-navigation) {
  .category:focus {
    transform: scale(1.1);
    object-fit: cover;
    outline: none;
  }
}

body:global(.low-performance) {
  .category {
    transition: none !important;
  }
}

.category_list {
  display: flex;
  overflow-x: auto;
  margin: 1.6rem 0 3.2rem;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media("<=tablet") {
    padding: 0;
    margin: 1.6rem 0 3.2rem 0;
    gap: 1.6rem;
  }
  @include media("<=phoneXL") {
    gap: 1rem;
  }
  @include media("<=desktop720p") {
    // margin-right: -$category-padding;
  }

  .category {
    cursor: pointer;
    padding: $category-padding;
    transition: 0.5s all;

    &.active,
    &:hover {
      transform: scale(1.1);
      object-fit: cover;
    }

    @include media("<=tablet") {
      padding: 0;
    }
    @include media("<=phoneXL") {
      // padding: 0 1rem 1rem 0;
    }
  }

  .category_img {
    width: 100%;
    height: var(--category-width);
    box-shadow: 0px 6.99997px 9.99996px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    
    .cover_empty {
      box-shadow: inset 0 0 10px 7px $color-light-transparent-15;
      width: var(--category-width);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 8px;
      background-color: rgba($color-gray, 0.05);
      svg {
        width: 10rem;
        height: 2rem;
      }
    }
    .cover_wrapper {
      position: relative;
      width: var(--category-width);
      height: var(--category-width);
    }
    .cover {
      border-radius: 8px;
    }
  }

  .category_details {
    color: white;
  }

  .category_name {
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 600;
    width: var(--category-width);

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }
}

.placeholder_item {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  width: var(--category-width);
  height: var(--category-width);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: var(--category-width);
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(#e8e8e8, 0.2) 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

:export {
  categoryOuterWidth: var(--category-width) + (2 * $category-padding);
}
