@import "../../../styles/variables.module";

$podcast-img-size: 11rem;
$podcast-width: 30rem;
$podcast-horizontal-padding: 1.6rem;

body:global(.arrow-navigation) {
  .podcast:focus .podcast_img {
    @include media(">phoneXL") {
      transform: scale(1.2);
      object-fit: cover;
    }
  }
}

body:global(.low-performance) {
  .podcast_img {
    transition: none !important;
  }
  .placeholder_item {
    &:before {
      display: none;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 1.6rem;
  position: relative;
  z-index: $z-index-base;

  @include media(">tablet") {
    padding-right: 1.6rem;
  }

  @include media("<=tablet") {
    padding: 0;
    align-items: center;
  }

  h2 {
    font-size: 3.2rem;
    color: $color-light;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include media("<=tablet") {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  .buttons {
    flex-shrink: 0;
    direction: ltr;
  }

  .arrow {
    &:first-child {
      margin-right: 1.6rem;
    }

    svg {
      width: 4rem;
      height: 4rem;
    }

    &.arrow_back {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.podcast_list {
  overflow-x: auto;
  margin: 0.8rem -1.6rem 1.6rem 0;
  padding: 1.6rem;
  -ms-overflow-style: none;
  scrollbar-width: none;

  html[dir="rtl"] & {
    margin: 0.8rem -1.6rem 1.6rem 0;

    @include media(">desktop720p") {
      margin: 0.8rem 0 1.6rem -3.4rem;
    }
  
    @include media("<=tablet") {
      padding-right: 0rem;
    }
  }

  @include media(">desktop720p") {
    margin: 0.8rem -3.4rem 1.6rem 0;
  }

  @include media("<=tablet") {
    padding-left: 0rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .podcast {
    display: flex;
    height: $podcast-img-size;
    width: $podcast-width;
    flex: 0 0 $podcast-width;
    cursor: pointer;
    scroll-snap-align: start;
    padding-right: $podcast-horizontal-padding;

    &:hover .podcast_img {
      @include media(">phoneXL") {
        transform: scale(1.2);
        object-fit: cover;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .podcast_img {
    margin: 0 1.6rem 0 0;
    transition: 0.5s all;

    html[dir="rtl"] & {
      margin: 0 0 0 1.6rem;
    }

    .cover_wrapper {
      position: relative;
      width: $podcast-img-size;
      height: $podcast-img-size;
    }

    .cover {
      border-radius: 8px;
      box-shadow: inset 0 0 10px 7px $color-light-transparent-15;

      &.no_image {
        background: $color-light-transparent-15;

        svg {
          width: calc(#{$podcast-img-size} - 2rem);
          height: calc(#{$podcast-img-size} - 2rem);
          margin: 1rem;
        }
      }
    }
  }

  .podcast:hover .podcast_img {
    @include media(">phoneXL") {
      transform: scale(1.2);
      object-fit: cover;
    }
  }

  .podcast_details {
    color: white;
  }

  .podcast_name {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 0.5rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include media("<tablet") {
      font-size: 1.6rem;
      line-height: 2.2rem;
      -webkit-line-clamp: 2;
    }
  }

  .podcast_description {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7rem;
    color: rgba(255, 255, 255, 0.6);

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    word-break: break-word;

    @include media("<tablet") {
      -webkit-line-clamp: 3;
    }
  }
}

.row {
  display: flex;

  &::after {
    content: "";
    flex: 0 0 calc(100vw - $podcast-width - 6.4rem);

    @include media("<=desktop720p") {
      flex: 0 0 calc(100vw - $podcast-width - 4.8rem);
    }
  }
}

.row + .row {
  margin-top: 1.6rem;
}

.placeholder_item {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  width: 15.6rem;
  height: 15.6rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 15.6rem;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(#e8e8e8, 0.2) 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes load {
  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}

:export {
  podcastOuterWidth: $podcast-width;
}
