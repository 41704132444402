@import "../../../styles/variables.module";

$extra-padding: 4rem;
$country-width: 16rem;

.header {
  display: flex;
  justify-content: space-between;
  transform: translateY(2.4rem);
  padding: 1.6rem;
  position: relative;
  z-index: $z-index-base + 2;

  h2 {
    font-size: 3.2rem;
    color: $color-light;

    @include media("<=tablet") {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  .buttons {
    flex-shrink: 0;
    direction: ltr;
  }
  .arrow {
    &:first-child {
      margin-right: 1.6rem;
    }
    svg {
      width: 4rem;
      height: 4rem;
    }
    &.arrow_back {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

body:global(.arrow-navigation) {
  .radio_station:focus {
    transform: scale(1.1);
    object-fit: cover;
  }
}

body:global(.low-performance) {
  .radio_station {
    transition: none !important;
  }
}

.radio_list {
  overflow-x: auto;
  padding: calc(3.2rem + #{$extra-padding}) 1.6rem;
  margin: -1 * $extra-padding -1.6rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  @include media("<=tablet") {
    padding: calc(3.2rem + #{$extra-padding}) 0rem $extra-padding;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .radio_station {
    transition: 0.5s all;
    cursor: pointer;
    border-radius: 100%;
    z-index: $z-index-base + 1;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.4));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    &:hover {
      @include media(">phoneXL") {
        transform: scale(1.2);
        filter: drop-shadow(0px 0px 24px #000000);
      }
    }

    &:focus {
      outline: none;
    }

    .cover {
      border-radius: 100%;
      object-fit: cover;
      object-position: center center;
      width: 102%;
      height: 102%;
      box-shadow: inset 0 0 10px 7px $color-light-transparent-15;

      &.no_image {
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 90%;
        svg {
          width: 10rem;
          height: 10rem;
          margin: 1rem;
        }
      }
    }

    .station_title {
      color: $color-light;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 2.4rem;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
      overflow: hidden;
      width: $country-width;
      text-overflow: ellipsis;
      text-align: center;
      margin-top: 1.6rem;
    }
    &.show_station_names.show_station_names {
      margin-bottom: 3.2rem;
    }
  }
}

.row {
  display: flex;

  &::after {
    content: "";
    flex: 0 0 calc($content-max-width - $country-width - 4.8rem);

    @include media("<=desktop1920") {
      flex: 0 0 calc(100vw - $country-width - 6.8rem);
    }

    @include media("<=phoneXL") {
      flex: 0 0 calc(100vw - $country-width - 0.8rem);
    }
  }

  &.wrapped {
    overflow-x: hidden;
    flex-wrap: wrap;
    padding-bottom: 3rem;

    .radio_station {
      margin: 1.6rem;
    }

    .placeholder_item {
      @include media("<=phone") {
        width: 11.2rem;
        height: 11.2rem;
      }
    }
    .radio_station {
      @include media("<=phone") {
        margin: 0.8rem;
      }
    }
  }
}

.placeholder_item {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 100%;
  width: 15.6rem;
  height: 15.6rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 15.6rem;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(#e8e8e8, 0.2) 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

:export {
  countryWidth: $country-width;
}
